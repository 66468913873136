
const message = (submissionLink, success) => {
  return (
    <span className="introMessage">
      <h2>{success ? "Bingo!" : "Introduction"}</h2>
        <div className="instructions">
          <ul>
            <li>Strategize with your territory on how you will be able to achieve BINGO together.</li>
            <li>Elect one person from your territory to mark off the tasks each member of your team completes.</li>
            <li>When you achieve a BINGO, take a screenshot of this page, and submit through this link!</li>
          </ul>
        </div>
        <a className="Button" href={submissionLink}>Upload My Screenshot</a>
        <div className="small center">Tech issues?: Email questions or a picture of your BINGO to Tara Hank at <a href="mailto:tara.hank2@astrazeneca.com">tara.hank2@astrazeneca.com</a></div>
    </span>
  );
};

const freeSpace = "BREZTRI";

const data = {
  '/PCRI' : {
    gameData: [
      "Share the Clinical Leave Behind with 5 P1 Customers",
      "Discuss EROS with a P1 or P2 customer",
      "Listen to the binaural experience and share the impact it had on you with your district",
      "Host 3 BREZTRI BLITZ 5/20-5/24",
      "Send the new Social Proof Email",
      "Host 4+ BREZTRI BLITZ 5/20-5/24",
      "Get 5 P1 Customers to a Dinner Program",
      "Listen to a BREZTRI Medical Minute",
      "Get an email address for a provider without one",
      "Drop ship a Clinical Leave Behind to a no-see office",
      "Sign up a new HCP for SMS texts",
      "Order the BREZTRI Protector Pack",
      freeSpace,
      "Follow up a P1 or P2 call with a SMS text",
      "Host 5+ Access Engagements During a Non-Blitz week",
      "Practice verbalization with my DSM",
      "Share a BREZTRI best practice on your regional Workplace page or chat",
      "Host 4+ BREZTRI BLITZ 4/29-5/3",
      "Action on a Veeva Suggestion",
      "Share On-Demand Video with 3 P2 Customers",
      "Gain a commitment to start BREZTRI earlier",
      "Host 3 BREZTRI BLITZ 4/29-5/3",
      "For 1 week, follow up every P1 call with a BREZTRI Veeva email",
      "Share On-Demand Video with 3 P1 Customers",
      "Share the Clinical Leave Behind with 5 P2 Customers",
    ],
    introMessage: message('https://blueprintnyc.typeform.com/to/QT3mt7R4'),
    successMessage: message('https://blueprintnyc.typeform.com/to/QT3mt7R4', true),
    freeSpace,
  },
  '/PCCVRM' : {
    gameData: [
      "Share a BREZTRI best practice on your regional Workplace page or chat",
      "Share the Clinical Leave Behind with 5 Priority 2 Customers",
      "Action on a Veeva Suggestion",
      "Host 3+ Access Engagements During a Non-Blitz week",
      "Share On-Demand Video with 3 Priority 1 Customers",
      "Listen to a BREZTRI Medical Minute",
      "Order the BREZTRI Protector Pack",
      "Share On-Demand Video with 3 Priority 2 Customers",
      "Sign up a new HCP for SMS texts",
      "Executed on OPC in every BREZTRI call for 1 week",
      "Share the Clinical Leave Behind with 5 Priority 1 Customers",
      "Send the new Primary Care Core Story Email",
      freeSpace,
      "Host a BREZTRI BLITZ 5/20-5/24",
      "Discuss EROS with a Priority 1 or Priority 2 customer",
      "Get an email address for a provider without one",
      "Follow up a Priority 1 or Priority 2 call with a SMS text",
      "Host a BREZTRI BLITZ 4/29-5/3",
      "Drop ship a Clinical Leave Behind to a no-see office",
      "Send the New NASCAR Email",
      "Follow up every Priority 2 call with a BREZTRI Veeva email",
      "For 1 week, follow up every Priority 1 call with a BREZTRI Veeva email",
      "Gain a commitment to start BREZTRI earlier",
      "Listen to the binaural experience and share the impact it had on you with your district",
      "Watch a COPD: It's Personal Video",
    ],
    introMessage: message('https://blueprintnyc.typeform.com/to/KWxmI3CI'),
    successMessage: message('https://blueprintnyc.typeform.com/to/KWxmI3CI', true),
    freeSpace,
  },
  '/RST' : {
    gameData: [
      "Sign up 2 new HCPs for SMS texts",
      "Host 2 BREZTRI BLITZ 5/20-5/24",
      "Have a presence or register for a regional congress or event",
      "Action on a Veeva Suggestion",
      "Listen to a BREZTRI Medical Minute",
      "Host 3+ BREZTRI BLITZ 5/20-5/24",
      "For 1 week, follow up every P1 call with a BREZTRI Veeva email",
      "Follow up a P1 or P2 call with a SMS text",
      "Share On-Demand Video with 3 P2 Customers",
      "Gain a commitment to step up a dual therapy patient to BREZTRI ",
      "Discuss EROS with a P1 or P2 customer",
      "Get an email address for a provider without one",
      freeSpace,
      "Order the BREZTRI Protector Pack",
      "Share a BREZTRI best practice on your regional Workplace page or chat",
      "Share On-Demand Video with 3 P1 Customers",
      "Deliver KRONOS, ETHOS and Mortality to every PULM call for 1 week",
      "Host 5+ Access Meal Engagements During a Non-Blitz week",
      "Host 2 BREZTRI BLITZ 4/29-5/3",
      "Practice mortality verbalization with my DSM",
      "Share the Clinical Leave Behind with 5 P2 Customers",
      "Share the Clinical Leave Behind with 5 P1 Customers",
      "Host 3+ BREZTRI BLITZ 4/29-5/3",
      "Get 3+ P1 Customers to a Dinner Program",
      "Drop ship a Clinical Leave Behind to a no-see office",
    ],
    introMessage: message('https://blueprintnyc.typeform.com/to/mn9eZqi2'),
    successMessage: message('https://blueprintnyc.typeform.com/to/mn9eZqi2', true),
    freeSpace,
  },
};

export const getData = () => {
  const { pathname } = window.location;
  const lowerPath = pathname.toLowerCase();
  const { gameData, introMessage, successMessage, freeSpace } = (lowerPath === '/pccvrm') ? data['/PCCVRM'] : (lowerPath === '/rst') ? data['/RST'] : data['/PCRI'];

  return { gameData, introMessage, successMessage, freeSpace };
}