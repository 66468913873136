import React from 'react';

import './Card.scss';
const logoSrc = "https://media.bpdev.club/BlueprintNYC/Testing%20Event/1712852476944BeastMode_BINGO_ThemeGraphic_800x450.png";

const Card = ({ thing, onClick, isSelected, animation, freeSpace }) => {
  const handleClick = () => {
    if(onClick && thing){
      onClick(thing);
    }
  };
  const longClass = (thing && thing.length > 60) ? 'long' : '';
  const disabledClass = isSelected ? 'disabled' : '';
  const animationClass = isSelected ? animation : '';
  const cardText = (thing === freeSpace) ? (
    <img alt={thing} src={logoSrc} />
  ) : thing;

  return (
    <div className={`Card ${disabledClass} ${animationClass} ${longClass}`} onClick={handleClick}>
      {cardText}
    </div>
  );
};

export default Card;
