import React from 'react';

import './Logo.scss';

const logoSrc = "https://media.bpdev.club/BlueprintNYC/Testing%20Event/1712852476944BeastMode_BINGO_ThemeGraphic_800x450.png";

const Logo = () => {
  return (
    <div className="Logo">
      <img alt="Bingo" src={logoSrc} />
    </div>
  );
};

export default Logo;
