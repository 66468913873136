import React from 'react';
import Card from './Card';

import './Things.scss';

const Things = ({ things, onSelect, selected, cardAnimation, freeSpace }) => {

  const handleThingClick = thing => {
    console.log("The following thing was selected:");
    console.log(thing);
    if(onSelect && thing){
      onSelect(thing);
    }
  }

  const thingCards = things.map(thing => {
    const isSelected = selected && selected.includes(thing);
    return (
      <Card
        key={thing}
        thing={thing}
        isSelected={isSelected}
        onClick={handleThingClick}
        animation={cardAnimation}
        freeSpace={freeSpace}
      />
    );
  })

  return (
    <div className="Things">
      { thingCards }
    </div>
  );
};

export default Things;
