import React, { useState, useEffect } from 'react';

import Message from './Message';
import Things from './Things';
import Logo from './Logo';

import { getData } from './data';

import './App.scss';

const { pathname } = window.location;

const bingos = [
  [0,1,2,3,4],
  [5,6,7,8,9],
  [10,11,12,13,14],
  [15,16,17,18,19],
  [20,21,22,23,24],
  [0,5,10,15,20],
  [1,6,11,16,21],
  [2,7,12,17,22],
  [3,8,13,18,23],
  [4,9,14,19,24],
  [0,6,12,18,24],
  [4,8,12,16,20],
];

const { gameData, introMessage, successMessage, freeSpace } = getData();

const App = () => {
  const [completed, setCompleted] = useState(false);
  const [selected, setSelected] = useState([freeSpace]);
  const [message, setMessage] = useState(introMessage);

  useEffect(() => {
    const localSelected = localStorage.getItem(pathname);
    console.log("local selected is ");
    console.log(localSelected);
    try {
      const newSelected = JSON.parse(localSelected);
      if(newSelected.length && newSelected.length > 1){
        setSelected(newSelected);
      }
    } catch (e) {
      console.log("error parsing local selected, but it's ok.");
    }
  }, []);

  useEffect(() => {
    const isBingo = () => {
      return bingos.some(
        bingoIDs => bingoIDs.every(id => selected.includes(gameData[id]))
      );
    }
    localStorage.setItem(pathname, JSON.stringify(selected));
    if(isBingo()){
      setCompleted(true);
    }else {
      setCompleted(false);
    }
  }, [selected]);

  useEffect(() => {
    if(completed){
      setMessage(successMessage);
    }else if(selected.length > 0){
      setMessage(introMessage);
    }
  }, [completed, selected.length]);

  const handleSelect = thing => {
    if(selected.includes(thing) && thing !== freeSpace){
      setSelected([...selected ].filter(t => t !== thing));
      setMessage(introMessage);
    }else {
      setSelected([...selected, thing ]);
    }
  }

  return (
    <div className="App">
      <div className="main-container">
        <Message message={message} logo={<Logo />}/>
        <Things things={gameData} freeSpace={freeSpace} selected={selected} onSelect={handleSelect} />
      </div>
    </div>
  );
}

export default App;
